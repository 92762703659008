import "./Up.css"
import {Link} from "react-scroll"
import { useState } from "react"

function Up () {
    const [showUp, setShowUp] = useState(false);

   

    function scrollUp () {
        const scrolled = document.documentElement.scrollTop
        if(scrolled > 300) {
            setShowUp(true)
        } else if (scrolled <= 300) {
            setShowUp(false)
        } 
    }

    window.addEventListener('scroll', scrollUp)

    // const scrollUp = window.onscroll = window.pageYOffset
//    console.log("scroll")
      
      function scrollingUp () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }

    return (
        <div className="up" onClick={scrollingUp} style={{right: showUp ? '30px' : '-100px'}}>
            <span></span>
            <span></span>
            
            

        </div>
    )
}

export default Up;