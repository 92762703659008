import "./FeedBack.css"
import mail from "../../utils/mail.php"
import video from "../../images/blue_plate.mov"
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// import gangga from "../../images/gangga.ru.png"
// import elton from "../../images/elton.ru.png"
// import {Carousel} from "react-bootstrap"

function FeedBack ({isOpen, onClose}) {

    const captcha = useRef(null)
    const [gcaptcha, setGcaptcha] = useState(false);

    function onChange () {
        if(captcha.current.getValue()) {
            setGcaptcha(true)
        }
        else setGcaptcha(false)
    }
   
    
        // eslint-disable-next-line no-undef
        // let gcaptcha = grecaptcha.getResponse();
        // if (gcaptcha.length === 0) {
        //     setCaptcha(false)
        //     // alert('error')
        //     return
        // }
        // else {setCaptcha(true); alert('succesful')}\
      
    //    fetch('https://www.google.com/recaptcha/api/siteverify', {
    //     method: 'POST',
    //     headers: {
    //         'secret': '6Ld35NEhAAAAAIFz2xrjUyrKobAo8MDTTEgLj7ie',
    //         'response': ''
    //     }
    //    })
    //    .then((res) =>
    //    res.ok ? res.json() : Promise.reject("Ошибка на сервере"))
    //    .then((res) => {
    //     return console.log(res)
    //    })
       
      
    return (
        
        <section id="feedbacks" className="feedback">
            {/* <h2 className="portfolio__title">Обратная связь</h2> */}
            
            <div className={`feedback__form ${isOpen ? "feedback__form_open" : ""}`}>
            {/* <video className="feedback__video" autoPlay muted loop>
                <source src={video}></source>
            </video> */}
            <form id="formId" className="form"  action={mail} method="post">
                <p className="form__text">Пожалуйста, заполните форму ниже. Напишите что нужно сделать, что не получается, прикрепите ссылку на сайт. Я изучу вопрос и отвечу Вам. </p>
    <label className="form__label" for="fio">Ваше имя <span class="form__span">*</span></label>
    <input className="form__input" id="fio" type="text" name="fio" placeholder="Имя" required/>
    <label className="form__label" for="phone">Почта <span class="form__span">*</span></label>
    <input className="form__input" id="phone" type="email" name="email" placeholder="Email" required />
    {/* <label className="form__label" for="year">Год выпуска <span class="form__span">*</span></label> */}
    {/* <input className="form__input" id="year"  type="number" name="year" placeholder="Год выпуска автомобиля" required /> */}
    <label className="form__label" for="massage">Какую задачу нужно решить? <span class="form__span">*</span></label>
    <textarea className="form__input form__input_text" id="massage" rows="10" cols="45" name="massage" placeholder="Напишите подробно что нужно сделать" required></textarea>
    {/* <label className="form__label" for="details">Номера деталей <span class="form__span form__span_details">(не обязательно)</span></label> */}
    {/* <textarea className="form__input form__input_text" id="details" rows="10" cols="45" name="details" placeholder="Укажите номера деталей, если имеются"></textarea> */}
    {/* <div className="g-recaptcha" id="g-recaptcha" ref={captcha} onChange={onChange} data-sitekey="6Ld35NEhAAAAAOHVh6CRTazbr_JztlyFtvxMDfef" /> */}
    <ReCAPTCHA
    className="recaptcha"
    sitekey="6Ld35NEhAAAAAOHVh6CRTazbr_JztlyFtvxMDfef"
    onChange={onChange}
    ref={captcha}
    />
    <button className="form__button" style={{background: gcaptcha ? '#1E87F0': 'grey', opacity: gcaptcha ? '1' : '0.6'}} disabled={gcaptcha ? false: true} type="submit">Отправить</button>
    
    <span className="form__span form__span_info">* - обязательно для заполнения</span>
    <button className="feedback__form_close" onClick={onClose}>&#10008;</button>
  </form>
            {/* <script src="https://yastatic.net/s3/frontend/forms/_/embed.js"></script><iframe src="https://forms.yandex.ru/u/60d4e1e60f713f12da702779/?iframe=1" name="ya-form-60d4e1e60f713f12da702779" title="Обратная связь" className="form"></iframe> */}
            {/* <script src="https://yastatic.net/s3/frontend/forms/_/embed.js"></script><iframe className="forms" src="https://forms.yandex.ru/u/607eb9f937c2a1940ebc87c5/?iframe=1" name="ya-form-607eb9f937c2a1940ebc87c5" title="Обратная связь" width="500" height="410"></iframe> */}
            
            
            </div>
            
        </section>
        
    )
}

export default FeedBack;