import video from "../../images/loading_bar.mp4"
import "./Blog.css"
function Blog () {
    return (
        
        <section className="blog">
            <video className="video" autoPlay loop muted>
                <source src={video}></source>
            </video>
            <h1 className="blog__title">Тут будет блог и статьи</h1>
        </section>
    )

}

export default Blog;